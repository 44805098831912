// CampaignStepper.jsx
import React, { useState, useEffect } from "react";
import { Typography, Box } from "@mui/material";
import Page from "../../components/Page";
import CustomizedVerticalSteppers from "../components-overview/material-ui/stepper/CustomizedVerticalStepper";
import { useNavigate, useParams } from "react-router-dom";
import axios from "../../utils/axios";
import TemplateSelector from "./TemplateSelector";
import LiveEmailEditor from "../../components/email-editor/LiveEmailEditor";
import TestAndPublishCampaign from "./TestAndPublishCampaign";
import CampaignFileUpload from "./CampaignFileUpload";

export default function CampaignStepper() {
	const [campaign, setCampaign] = useState({});
	const [isLoading, setIsLoading] = useState(false);
	const { campaignId } = useParams();
	const navigate = useNavigate();

	useEffect(() => {
		if (campaignId) {
			fetchCampaign();
		}
	}, [campaignId]);

	const fetchCampaign = async () => {
		setIsLoading(true);
		try {
			const response = await axios.get(`/campaign/${campaignId}`);
			setCampaign(response.data);
		} catch (error) {
			console.error("Failed to fetch campaign:", error);
		} finally {
			setIsLoading(false);
		}
	};

	async function sendTestMessage(recipients) {
		const payload = { recipients, publish: false };
		const response = await axios.post(
			`/campaign/dispatch/${campaignId}`,
			payload
		);
		return response.data;
	}

	async function sendPublishMessage() {
		const payload = { publish: true };
		const response = await axios.post(
			`/campaign/dispatch/${campaignId}`,
			payload
		);
		return response.data;
	}

	const handleUpdateCampaign = async (updatedData) => {
		setIsLoading(true);
		try {
			await axios.put(`/campaign/${campaignId}`, updatedData);
			setCampaign({ ...campaign, ...updatedData });
		} catch (error) {
			console.error("Failed to update campaign:", error);
		} finally {
			setIsLoading(false);
		}
	};

	const steps = [
		{
			label: "Select Template",
			content: (
				<TemplateSelector
					onSelect={(templateObj) => handleUpdateCampaign(templateObj)}
				/>
			),
		},
		{
			label: "Select Campaign Data",
			content: <CampaignFileUpload campaignId={campaignId} />,
		},
		{
			label: "Enhance Campaign",
			content: campaign.emailJson && (
				<LiveEmailEditor
					json={JSON.parse(campaign.emailJson)}
					practiceId={campaign.practiceId}
					onUpdate={({ json, html }) =>
						handleUpdateCampaign({ emailJson: json, emailHtml: html })
					}
				/>
			),
		},
		{
			label: "Test and Publish Campaign",
			content: (
				<TestAndPublishCampaign
					campaign={campaign}
					onTestSend={sendTestMessage}
					onPublishCampaign={sendPublishMessage}
				/>
			),
		},
	];

	return (
		<Page>
			<Box
				sx={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
					mb: 2,
				}}
			>
				<Typography variant="h4">Campaigns</Typography>
			</Box>
			<CustomizedVerticalSteppers
				steps={steps}
				isLoading={isLoading}
				campaign={campaign}
			/>
		</Page>
	);
}
