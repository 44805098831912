import React, { useState } from "react";
import {
	Button,
	TextField,
	Box,
	Typography,
	CircularProgress,
	Snackbar,
	Alert,
	Chip,
	Stack,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import SendIcon from "@mui/icons-material/Send";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";

export default function TestAndPublishCampaign({
	onTestSend,
	onPublishCampaign,
	campaign = { type: "email" },
}) {
	const [recipients, setRecipients] = useState([]);
	const [recipientInput, setRecipientInput] = useState("");
	const [loading, setLoading] = useState(false);
	const [snackbarOpen, setSnackbarOpen] = useState(false);
	const [snackbarMessage, setSnackbarMessage] = useState("");
	const [snackbarSeverity, setSnackbarSeverity] = useState("info");
	const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

	const handleAddRecipient = () => {
		if (recipientInput && !recipients.includes(recipientInput)) {
			setRecipients([...recipients, recipientInput]);
			setRecipientInput("");
		}
	};

	const handleDeleteRecipient = (chipToDelete) => {
		setRecipients((chips) => chips.filter((chip) => chip !== chipToDelete));
	};

	const handleSendTest = async () => {
		if (recipients.length === 0) return;

		setLoading(true);
		try {
			const response = await onTestSend(recipients, campaign.type);
			setSnackbarMessage(
				`Test ${campaign.type} sent successfully to all recipients!`
			);
			setSnackbarSeverity("success");
		} catch (error) {
			setSnackbarMessage(
				`Failed to send test ${campaign.type}: ${error.message}`
			);
			setSnackbarSeverity("error");
		} finally {
			setLoading(false);
			setSnackbarOpen(true);
		}
	};

	const handlePublishCampaign = async () => {
		setLoading(true);
		try {
			await onPublishCampaign(campaign.id);
			setSnackbarMessage(`Campaign published successfully!`);
			setSnackbarSeverity("success");
		} catch (error) {
			setSnackbarMessage(`Failed to publish campaign: ${error.message}`);
			setSnackbarSeverity("error");
		} finally {
			setLoading(false);
			setSnackbarOpen(true);
			setOpenConfirmDialog(false);
		}
	};

	const handleOpenConfirmDialog = () => {
		setOpenConfirmDialog(true);
	};

	const handleCloseConfirmDialog = () => {
		setOpenConfirmDialog(false);
	};

	const handleCloseSnackbar = () => {
		setSnackbarOpen(false);
	};

	return (
		<Box
			sx={{
				p: 3,
				border: "1px solid #e0e0e0",
				borderRadius: "8px",
				boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
				mt: 2,
			}}
		>
			<Typography variant="h6" gutterBottom>
				Send Test Campaign to Multiple Recipients
			</Typography>
			<TextField
				fullWidth
				label={`Add Recipient ${
					campaign.type === "email" ? "Email" : "Phone Number"
				}`}
				variant="outlined"
				value={recipientInput}
				onChange={(e) => setRecipientInput(e.target.value)}
				onKeyPress={(event) => {
					if (event.key === "Enter") {
						handleAddRecipient();
						event.preventDefault();
					}
				}}
				margin="normal"
				InputProps={{
					endAdornment: (
						<Button onClick={handleAddRecipient} color="primary">
							<AddCircleOutlineIcon />
						</Button>
					),
				}}
			/>
			<Stack direction="row" spacing={1} sx={{ mb: 2 }}>
				{recipients.map((recipient, index) => (
					<Chip
						key={index}
						label={recipient}
						onDelete={() => handleDeleteRecipient(recipient)}
						color="primary"
						icon={
							campaign.type === "email" ? (
								<MailOutlineIcon />
							) : (
								<PhoneAndroidIcon />
							)
						}
					/>
				))}
			</Stack>
			<Box sx={{ display: "flex", alignItems: "center" }}>
				<Button
					variant="contained"
					color="primary"
					disabled={loading || recipients.length === 0}
					onClick={handleSendTest}
					startIcon={loading && <CircularProgress size={24} />}
					endIcon={!loading && <SendIcon />}
				>
					Send Test {campaign.type === "email" ? "Emails" : "Texts"}
				</Button>
				<Button
					variant="contained"
					color="secondary"
					disabled={campaign.status == "Published"  || loading}
					onClick={handleOpenConfirmDialog}
					sx={{ ml: 2 }}
				>
					Publish Campaign
				</Button>
			</Box>
			<Dialog
				open={openConfirmDialog}
				onClose={handleCloseConfirmDialog}
				aria-labelledby="confirm-publish-dialog-title"
			>
				<DialogTitle id="confirm-publish-dialog-title">
					Confirm Publish
				</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Are you sure you want to publish this campaign?
						{campaign.publishedTime && (
							<>
								<br />
								Scheduled Publish Time:{" "}
								{new Date(campaign.publishedTime).toLocaleString()}
							</>
						)}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleCloseConfirmDialog}>Cancel</Button>
					<Button onClick={handlePublishCampaign} color="primary">
						Confirm
					</Button>
				</DialogActions>
			</Dialog>
			<Snackbar
				open={snackbarOpen}
				autoHideDuration={6000}
				onClose={handleCloseSnackbar}
			>
				<Alert
					onClose={handleCloseSnackbar}
					severity={snackbarSeverity}
					sx={{ width: "100%" }}
				>
					{snackbarMessage}
				</Alert>
			</Snackbar>
		</Box>
	);
}
